// ******************************************************
// ******     START PrimeNG Designer Variables     ******
// ******************************************************

//main color scheme
$primaryColor: $CTA-icon-cyan;
$primaryDarkColor: $FDS-uniting-blue;
$primaryDarkerColor: $FDS-ford-blue;
$primaryTextColor: $white;

//Global
$fontFamily:$fontFamilyDefault;
$fontSize:16px;
$textColor: $charcoal;
$textSecondaryColor: $FDS-dark-gray;
$borderRadius:3px;
$transitionDuration:.2s;
$primeIconFontSize:1em;

//anchors
$linkColor:$primaryDarkerColor;
$linkHoverColor: $primaryDarkColor;
$linkActiveColor:$primaryDarkerColor;

//highlight
$highlightBgColor:$primaryDarkColor;
$highlightColorText:$primaryTextColor;

//focus
$focusOutlineColor: lighten($primaryColor, 35%);
$focusOutline: 0 none;
$focusOutlineOffset: 0;
$focusShadow: 0 0 0 0.2em $focusOutlineColor;
//input field (e.g. inputtext, spinner, inputmask)
$inputPadding:0.429em;
$inputBgColor: $white;
$inputBorder:1px solid $FDS-disabled-dark_light-background;
$inputHoverBorderColor: $primaryDarkColor;
$inputFocusBorderColor:$primaryColor;

$inputErrorBorder:1px solid $FDS-error;
$inputPlaceholderTextColor: $FDS-dark-gray;
$inputTransition:border-color $transitionDuration;

//input groups
$inputGroupBorderColor: $primaryDarkerColor;
$inputGroupBgColor: $primaryDarkColor;
$inputGroupTextColor:$primaryTextColor;

//input lists (e.g. dropdown, autocomplete, multiselect, orderlist)
$inputListMinWidth:12em;
$inputListBgColor: $white;
$inputListPadding:0;
$inputListItemPadding:0.429em 0.857em;
$inputListItemBgColor:transparent;
$inputListItemTextColor:$textColor;
$inputListItemHoverBgColor: $primaryDarkColor;
$inputListItemHoverTextColor:$primaryTextColor;
$inputListItemHighlightBgColor:$highlightBgColor;
$inputListItemHighlightTextColor:$highlightColorText;
$inputListItemBorder:0 none;
$inputListItemMargin:0;
$inputListItemFocusShadow: inset 0 0 0 0.2em lighten($primaryColor, 35%);
$inputListItemTransition: background-color $transitionDuration, box-shadow $transitionDuration;
$inputListItemDividerColor: $FDS-disabled-dark_light-background;
$inputListHeaderPaddingTop:0.429em;
$inputListHeaderPaddingLeft:0.857em;
$inputListHeaderPaddingRight:0.857em;
$inputListHeaderPaddingBottom:0.429em;
$inputListHeaderMargin:0;
$inputListHeaderBgColor: $white;
$inputListHeaderTextColor:$textColor;
$inputListHeaderBorder:1px solid $FDS-disabled-dark_light-background;
$inputListHeaderSearchIconColor:$primaryColor;
$inputListHeaderCloseIconColor:$textSecondaryColor;
$inputListHeaderCloseIconHoverColor:$textColor;
$inputListHeaderCloseIconTransition:color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding:12px;
$inputContentPanelBgColor: $white;
$inputContentPanelTextColor:$textColor;

//static positioned input lists like listbox
$inputListBorder:1px solid $FDS-disabled-dark_light-background;

//inputs with overlays (e.g. autocomplete, dropdown, multiselect)
$inputOverlayBorder:1px solid $FDS-surface;
$inputOverlayShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);

//input dropdowns (e.g. multiselect, dropdown)
$inputDropdownIconColor:$textSecondaryColor;

//button
$buttonTextOnlyPadding:0.429em 1em;
$buttonWithLeftIconPadding:0.429em 1em 0.429em 2em;
$buttonWithRightIconPadding:0.429em 2em 0.429em 1em;
$buttonIconOnlyPadding:0.429em;
$buttonIconOnlyWidth:2.357em;
$buttonBgColor:$primaryDarkerColor;
$buttonBorder:1px solid $primaryDarkerColor;
$buttonTextColor:$primaryTextColor;
$buttonHoverBgColor:$primaryDarkColor;
$buttonHoverTextColor:$primaryTextColor;
$buttonHoverBorderColor: $primaryDarkColor;
$buttonActiveBgColor: $primaryDarkColor;
$buttonActiveTextColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkColor;
$buttonFocusBorderColor: $primaryDarkColor;
$buttonFocusOutline:0 none;
$buttonFocusOutlineOffset:0px;
$buttonFocusShadow:0 0 0 0.1em lighten($primaryColor, 35%);
$buttonTransition:background-color $transitionDuration,box-shadow $transitionDuration;
$raisedButtonShadow:0 2px 3px 0 rgba(0, 0, 0, 0.15);
$roundedButtonBorderRadius:15px;

$secondaryButtonBgColor: $FDS-surface;
$secondaryButtonBorder:1px solid $FDS-disabled-dark_light-background;
$secondaryButtonTextColor: $charcoal;
$secondaryButtonHoverBgColor: darken($white, 10%);
$secondaryButtonHoverTextColor: $charcoal;
$secondaryButtonHoverBorderColor: $FDS-dark-gray;
$secondaryButtonActiveBgColor: $white;
$secondaryButtonActiveTextColor: $charcoal;
$secondaryButtonActiveBorderColor: $FDS-dark-gray;
$secondaryButtonFocusShadow:0 0 0 0.1em lighten($primaryColor, 35%);

$infoButtonBgColor:$primaryColor;
$infoButtonBorder:1px solid $primaryDarkColor;
$infoButtonTextColor:$primaryTextColor;
$infoButtonHoverBgColor:$primaryDarkColor;
$infoButtonHoverTextColor:$primaryTextColor;
$infoButtonHoverBorderColor:$primaryColor;
$infoButtonActiveBgColor:$primaryDarkColor;
$infoButtonActiveTextColor:$primaryTextColor;
$infoButtonActiveBorderColor:$primaryColor;
$infoButtonFocusShadow:0 0 0 0.1em lighten($infoButtonBgColor, 35%);

$successButtonBgColor: $FDS-success;
$successButtonBorder:1px solid $FDS-success;
$successButtonTextColor:$primaryTextColor;
$successButtonHoverBgColor:darken($successButtonBgColor, 10%);
$successButtonHoverTextColor:$primaryTextColor;
$successButtonHoverBorderColor: $FDS-success;
$successButtonActiveBgColor:darken($successButtonHoverBgColor, 10%);
$successButtonActiveTextColor:$primaryTextColor;
$successButtonActiveBorderColor: $FDS-success;
$successButtonFocusShadow:0 0 0 0.1em lighten($successButtonBgColor, 35%);

$warningButtonBgColor: $FDS-caution_graphical-objects;
$warningButtonBorder:1px solid $FDS-caution_graphical-objects;
$warningButtonTextColor:$primaryTextColor;
$warningButtonHoverBgColor:darken($warningButtonBgColor, 15%);
$warningButtonHoverTextColor:$primaryTextColor;
$warningButtonHoverBorderColor: $FDS-caution_graphical-objects;
$warningButtonActiveBgColor:darken($warningButtonHoverBgColor, 15%);
$warningButtonActiveTextColor:$primaryTextColor;
$warningButtonActiveBorderColor: $FDS-caution_graphical-objects;
$warningButtonFocusShadow:0 0 0 0.1em lighten($warningButtonBgColor, 35%);

$dangerButtonBgColor: $FDS-error;
$dangerButtonBorder:1px solid $FDS-error;
$dangerButtonTextColor:$primaryTextColor;
$dangerButtonHoverBgColor:darken($FDS-error, 10%);
$dangerButtonHoverTextColor:$primaryTextColor;
$dangerButtonHoverBorderColor: $FDS-error;
$dangerButtonActiveBgColor:darken($dangerButtonHoverBgColor, 10%);
$dangerButtonActiveTextColor:$primaryTextColor;
$dangerButtonActiveBorderColor: $FDS-error;
$dangerButtonFocusShadow:0 0 0 0.1em lighten($dangerButtonBgColor, 35%);

//checkbox
$checkboxWidth:20px;
$checkboxHeight:20px;
$checkboxActiveBorderColor:$primaryDarkColor;
$checkboxActiveBgColor:$primaryDarkColor;
$checkboxActiveTextColor:$primaryTextColor;
$checkboxActiveHoverBgColor:$primaryDarkColor;
$checkboxActiveHoverTextColor:$primaryTextColor;
$checkboxActiveHoverBorderColor:$primaryDarkColor;

$checkboxTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonActiveBorderColor:$primaryDarkerColor;
$radiobuttonActiveBgColor:$primaryDarkColor;
$radiobuttonActiveTextColor:$primaryTextColor;
$radiobuttonActiveHoverBgColor:$primaryDarkColor;
$radiobuttonActiveHoverTextColor:$primaryTextColor;
$radiobuttonActiveHoverBorderColor:$primaryDarkColor;

$radiobuttonTransition:background-color $transitionDuration, border-color $transitionDuration, box-shadow $transitionDuration;

//togglebutton
$toggleButtonBgColor: $buttonBgColor;
$toggleButtonBorder: $buttonBorder;
$toggleButtonTextColor: $buttonTextColor;
$toggleButtonIconColor: $primaryTextColor;
$toggleButtonHoverBgColor: $buttonHoverBgColor;
$toggleButtonHoverBorderColor: $buttonHoverBorderColor;
$toggleButtonHoverTextColor: $buttonHoverTextColor;
$toggleButtonHoverIconColor: $primaryTextColor;
$toggleButtonActiveBgColor: $buttonActiveBgColor;
$toggleButtonActiveBorderColor: $buttonActiveBorderColor;
$toggleButtonActiveTextColor: $buttonActiveTextColor;
$toggleButtonActiveIconColor: $primaryTextColor;
$toggleButtonActiveHoverBgColor: $primaryDarkColor;
$toggleButtonActiveHoverBorderColor: $primaryDarkColor;
$toggleButtonActiveHoverTextColor: $primaryTextColor;
$toggleButtonActiveHoverIconColor: $primaryTextColor;


//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBgColor: $FDS-surface;
$inplaceHoverTextColor:$textColor;
$inplaceTransition:background-color $transitionDuration;

//rating
$ratingTransition:color $transitionDuration;
$ratingCancelIconColor:$CTA-light-cyan;
$ratingCancelHoverIconColor:$primaryColor;
$ratingIconFontSize:20px;
$ratingStarIconColor:$textColor;
$ratingStarIconHoverColor:$primaryColor;

//slider
$sliderBgColor: $FDS-disabled-light_dark-background;
$sliderBorder:0 none;
$sliderHeight:.286em;
$sliderWidth:0.286em;
$sliderHandleWidth:1.143em;
$sliderHandleHeight:1.143em;
$sliderHandleBgColor: $white;
$sliderHandleBorder:2px solid $FDS-dark-gray;
$sliderHandleBorderRadius:100%;
$sliderHandleHoverBorder:2px solid $primaryColor;
$sliderHandleHoverBgColor:2px solid $FDS-dark-gray;
$sliderHandleTransition:border-color $transitionDuration;
$sliderRangeBgColor:$primaryDarkColor;

//calendar
$calendarWidth:20em;
$calendarNavIconColor: $FDS-disabled-dark_light-background;
$calendarNavIconHoverColor:$primaryColor;
$calendarNavIconTransition:color $transitionDuration;
$calendarPadding:0.857em;
$calendarTableMargin:0.857em 0 0 0;
$calendarHeaderCellPadding:.5em;
$calendarCellDatePadding:.5em;
$calendarCellDateHoverBgColor: $primaryDarkColor;
$calendarCellDateBorderRadius:$borderRadius;
$calendarCellDateSelectedBgColor:$primaryDarkColor;
$calendarCellDateSelectedTextColor:$primaryTextColor;
$calendarCellDateTodayBgColor: $primaryDarkerColor;
$calendarCellDateTodayTextColor:$primaryTextColor;
$calendarTimePickerDivider: 1px solid $FDS-dark-gray;
$calendarTimePickerPadding:0.857em;
$calendarTimePickerIconColor:$textColor;
$calendarTimePickerIconFontSize:1.286em;
$calendarTimePickerTimeFontSize:1.286em;
$calendarTimePickerIconHoverColor:$primaryColor;
$calendarButtonBarDivider: 1px solid $FDS-disabled-dark_light-background;
$calendarMultipleMonthDivider: 1px solid $FDS-disabled-dark_light-background;

//spinner
$spinnerButtonWidth:2em;

//input switch
$inputSwitchWidth:3em;
$inputSwitchHeight:1.75em;
$inputSwitchBorderRadius:30px;
$inputSwitchTransition:background-color $transitionDuration;
$inputSwitchSliderOffBgColor: $FDS-disabled-light_dark-background;
$inputSwitchHandleOffBgColor: $white;
$inputSwitchSliderOffHoverBgColor: $FDS-disabled-dark_light-background;
$inputSwitchSliderOffFocusBgColor: $FDS-disabled-dark_light-background;
$inputSwitchSliderOnBgColor:$primaryColor;
$inputSwitchSliderOnHoverBgColor:$primaryDarkColor;
$inputSwitchHandleOnBgColor: $FDS-disabled-dark_light-background;
$inputSwitchSliderOnFocusBgColor:$primaryDarkerColor;

//panel common (e.g. accordion, panel, tabview)
$panelHeaderBorder:1px solid $primaryDarkerColor;
$panelHeaderBgColor: $primaryDarkerColor;
$panelHeaderTextColor: $primaryTextColor;
$panelHeaderIconColor:$primaryTextColor;
$panelHeaderIconHoverColor: $primaryTextColor;
$panelHeaderIconTransition:color $transitionDuration;
$panelHeaderFontWeight:400;
$panelHeaderPadding:0.571em 1em;
$panelContentBorder:1px solid $primaryDarkerColor;
$panelContentBgColor: $white;
$panelContentTextColor:$textColor;
$panelContentPadding:0.571em 1em;
$panelFooterBorder:1px solid $primaryDarkerColor;
$panelFooterBgColor: $FDS-surface;
$panelFooterTextColor:$primaryTextColor;
$panelFooterPadding:0.571em 1em;
$panelHeaderHoverBgColor: $primaryDarkColor;
$panelHeaderHoverBorder:1px solid $FDS-disabled-dark_light-background;
$panelHeaderHoverTextColor: $primaryTextColor;
$panelHeaderHoverIconColor: $primaryTextColor;
$panelHeaderActiveBgColor:$primaryDarkColor;
$panelHeaderActiveBorder:1px solid $FDS-dark-gray;
$panelHeaderActiveTextColor:$primaryTextColor;
$panelHeaderActiveIconColor:$primaryTextColor;
$panelHeaderActiveHoverBgColor:$primaryDarkColor;
$panelHeaderActiveHoverBorder:1px solid $FDS-dark-gray;
$panelHeaderActiveHoverTextColor:$primaryTextColor;
$panelHeaderActiveHoverIconColor:$primaryTextColor;
$panelHeaderTransition:background-color $transitionDuration;

//accordion
$accordionSpacing:2px;

//tabview
$tabsNavBorder:0 none;
$tabsNavBgColor:transparent;
$tabHeaderSpacing: 2px;

//scrollpanel
$scrollPanelHandleBgColor: $FDS-surface;
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBgColor: $FDS-surface;

//card
$cardShadow:0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);

//paginator
$paginatorBgColor: $white;
$paginatorBorder: none;
$paginatorPadding:0;
$paginatorTextColor:$textColor;
$paginatorIconColor:$textColor;
$paginatorElementWidth:2.286em;
$paginatorElementHeight:2.286em;
$paginatorElementHoverBgColor: $primaryDarkerColor;
$paginatorElementHoverIconColor: $primaryTextColor;
$paginatorElementBorderRadius:3px;
$paginatorElementMargin:0;
$paginatorElementPadding:0;
$paginatorElementBorder:0 none;

//table
$tableCaptionFontWeight:700;
$tableSummaryFontWeight:700;
$tableHeaderCellPadding:0.571em 0.857em;
$tableHeaderCellBgColor:$primaryDarkerColor;
$tableHeaderCellTextColor:$primaryTextColor;
$tableHeaderCellFontWeight:700;
$tableHeaderCellBorder:1px solid $FDS-disabled-dark_light-background;
$tableHeaderCellHoverBgColor:$primaryDarkColor;
$tableHeaderCellHoverTextColor: $FDS-surface;
$tableHeaderCellIconColor:$primaryTextColor;
$tableHeaderCellHoverIconColor: $FDS-surface;
$tableBodyRowBgColor:$white;
$tableBodyRowTextColor:$textColor;
$tableBodyRowEvenBgColor:$even-row-color;
$tableBodyRowHoverBgColor: $FDS-disabled-dark_light-background;
$tableBodyRowHoverTextColor:$primaryTextColor;
$tableBodyCellBorder:1px solid $FDS-disabled-dark_light-background;
$tableBodyCellPadding:none;
$tableFooterCellPadding:none;
$tableFooterCellBgColor:$primaryDarkerColor;
$tableFooterCellTextColor:$primaryTextColor;
$tableFooterCellFontWeight:700;
$tableFooterCellBorder:1px solid $FDS-disabled-dark_light-background;
$tableResizerHelperBgColor:$primaryColor;

//schedule
$scheduleEventBgColor:$primaryDarkColor;
$scheduleEventBorder:1px solid $primaryDarkColor;
$scheduleEventTextColor:$primaryTextColor;

//tree
$treeNodePadding:0.143em 0;
$treeNodeLabelPadding:0.286em;
$treeNodeContentSpacing:0.143em;

//lightbox
$lightBoxNavIconFontSize:3em;
$lightBoxNavIconColor:$primaryTextColor;

//org chart
$organizationChartConnectorColor: $FDS-surface;

//messages
$messagesMargin:1em 0;
$messagesPadding:1em;
$messagesIconFontSize:2em;
$messageCloseIconFontSize:1.5em;

//message
$messagePadding:.429em;
$messageMargin: 0;
$messageIconFontSize: 1.25em;
$messageTextFontSize: 1em;

//toast
$toastShadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
$toastMessageMargin:0 0 1em 0;

//severities
$infoMessageBgColor:$CTA-light-cyan;
$infoMessageBorder:0 none;
$infoMessageTextColor: $primaryTextColor;
$infoMessageIconColor: $primaryTextColor;
$successMessageBgColor: $successButtonBgColor;
$successMessageBorder:0 none;
$successMessageTextColor: $primaryTextColor;
$successMessageIconColor: $primaryTextColor;
$warnMessageBgColor: $warningButtonBgColor;
$warnMessageBorder:0 none;
$warnMessageTextColor: $primaryTextColor;
$warnMessageIconColor: $primaryTextColor;
$errorMessageBgColor:$dangerButtonBgColor;
$errorMessageBorder:0 none;
$errorMessageTextColor: $primaryTextColor;
$errorMessageIconColor: $primaryTextColor;

//growl
$growlTopLocation:70px;
$growlIconFontSize:2.571em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin:0 0 1em 0;
$growlPadding:1em;
$growlShadow:0 3px 6px 0 rgba(0, 0, 0, 0.16);
$growlOpacity:.9;

//overlays
$overlayContentBorderColor: $FDS-surface;
$overlayContentBorder:1px solid $overlayContentBorderColor;
$overlayContainerShadow:0 0 6px 0 rgba(0, 0, 0, 0.16);

//dialog
$dialogHeaderPadding:1em;
$confirmDialogPadding:1em;

//overlay panel
$overlayPanelCloseIconBgColor:$primaryColor;
$overlayPanelCloseIconColor:$primaryTextColor;
$overlayPanelCloseIconHoverBgColor:$primaryDarkerColor;
$overlayPanelCloseIconHoverColor:$primaryTextColor;

//tooltip
$tooltipBgColor: $primaryDarkColor;
$tooltipTextColor:$primaryTextColor;
$tooltipPadding:$inputPadding;

//steps
$stepsItemBgColor: $white;
$stepsItemBorder:1px solid $FDS-dark-gray;
$stepsItemNumberColor:$textColor;
$stepsItemTextColor: $textColor;
$stepsItemWidth:28px;
$stepsItemHeight:28px;

//progressbar
$progressBarHeight:24px;
$progressBarBorder:0 none;
$progressBarBgColor: $FDS-disabled-dark_light-background;
$progressBarValueBgColor:$primaryDarkColor;

//menu (e.g. menu, menubar, tieredmenu)
$menuBgColor:$primaryDarkerColor;
$menuBorder: none; //1px solid $primaryDarkerColor;
$menuPadding:0;
$menuTextColor:$primaryTextColor;
$menuitemPadding:1em 1.25em;
$menuitemMargin:0.1em;
$menuitemTextColor:$primaryTextColor;
$menuitemIconColor:$primaryTextColor;
$menuitemHoverTextColor:$primaryTextColor;
$menuitemHoverIconColor:$primaryTextColor;
$menuitemHoverBgColor: $primaryDarkColor;
$menuitemActiveTextColor:$primaryTextColor;
$menuitemActiveIconColor:$primaryTextColor;
$menuitemActiveBgColor: $primaryDarkColor;
$menuitemRouterLinkActiveTextColor:$primaryTextColor;
$menuitemRouterLinkActiveIconColor:$primaryColor;
$submenuHeaderMargin: 0;
$submenuPadding: 0;
$overlayMenuBorder:2px solid $FDS-surface;
$overlayMenuShadow: 0.2em 0.2em 0.15em rgba(0, 0, 0, 0.2);

//misc
$maskBgColor:rgba(0, 0, 0, 0.4);        //dialog mask
$inlineSpacing:.5em;                      //spacing between inline elements
$chipsItemMargin:0 .286em 0 0;            //autocomplete and chips token spacing
$dataIconColor:$textSecondaryColor;       //icon color of a data such as treetoggler, table expander
$dataIconHoverColor:$textColor;           //hover icon color of a data such as treetoggler, table expander

//general
$disabledOpacity:.5;                      //opacity of a disabled item

//carousel
$carouselNavButtonsBgColor: $buttonBgColor;
$carouselNavButtonsBorder: $buttonBorder;
$carouselNavButtonsBorderRadius: 50%;
$carouselNavButtonsMargin: .2em;
$carouselNavButtonsColor: $buttonTextColor;
$carouselNavButtonsHoverBgColor: $primaryDarkColor;
$carouselNavButtonsHoverColor: $buttonHoverTextColor;
$carouselNavButtonsHoverBorderColor: $buttonHoverBorderColor;
$carouselNavButtonsTransition: $buttonTransition;
$carouselDotIconWidth: 20px;
$carouselDotIconHeight: 6px;
$carouselDotIconBgColor: #b2c1cd;
$carouselDotIconMargin: 0 .2em;
$carouselActiveDotIconBgColor: $primaryColor;
// ******************************************************
// ******      END PrimeNG Designer Variables      ******
// ******************************************************
