.ui-togglebutton {

    &:not(.ui-state-disabled):not(.ui-state-active):hover {
        box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
    }

    &:not(.ui-state-disabled):not(.ui-state-active).ui-state-focus {
        box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
    }

    &.ui-state-active {
        box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);

        &:hover {
            box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
        }

        &.ui-state-focus {
            box-shadow: inset 0 1em 1em rgba(255, 255, 255, 0.2), 0 0.2em 0.15em rgba(0, 0, 0, 0.5);
        }
    }
}
