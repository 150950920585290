.ui-panel {
    .ui-panel-titlebar {
        a {
            color: $white;
            text-decoration: underline;
        }
    }

    p-header {
        display: flex;
    }

    .ui-panel-content {

        &:not(:last-child){
            border-bottom: none;
        }

        h5 {
            margin: 0 !important;
        }

        .p-grid {
            margin-bottom: .5em !important;
        }
    }
}

.ui-panel-view {
    text-align: right;
    width: 100%;
    margin-top: -38px;
    margin-left: 13px;
}

.ui-dataview {
    .ui-dataview-content {
        .ui-panel-footer {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            display: flex;
            align-items: center;
            color: $textColor;
            margin-bottom: 2.75em;
            justify-content: space-between;

            a {
                &:hover {
                    color: $primaryColor;
                }

                color: $textColor;
                text-decoration: underline;
            }
        }
    }
}
