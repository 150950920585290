.ui-menubar {
    padding: $menuPadding;
    background-color: $menuBgColor;
    border: $menuBorder;

    .ui-menuitem-link {
        @include menuitem-link();
    }

    .ui-menubar-root-list {
        > .ui-menuitem {
            > .ui-menuitem-link {
                padding: $menuitemPadding;
            }
        }

        > .ui-menu-separator {
            border: $menuBorder;
            border-width: 0 0 0 1px;
        }
    }

    .ui-submenu-list {
        padding: $submenuPadding;
        background-color: $menuBgColor;
        border: $overlayMenuBorder;
        @include shadow($overlayMenuShadow);

        .ui-menuitem {
            margin: $menuitemMargin;
        }

        .ui-menu-separator {
            border: $menuBorder;
            border-width: 1px 0 0 0;
        }
    }

    .ui-menuitem {
        margin: $menuitemMargin;

        &.ui-menuitem-active {
            > .ui-menuitem-link {
                background-color: $menuitemActiveBgColor;

                .ui-menuitem-text {
                    color: $menuitemActiveTextColor;
                }

                .ui-menuitem-icon, .ui-submenu-icon {
                    color: $menuitemActiveIconColor;
                }
            }
        }
    }
}
