* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    .ui-widget {
        font-family: $fontFamily;
        font-size: $fontSize;
        text-decoration: none;
    }

    .ui-widget-overlay {
        background-color: $maskBgColor;
    }

    .ui-widget-content {
        p {
            line-height: 1.5;
            margin: 0;
        }
    }

    .ui-corner-all {
        @include border-radius($borderRadius);
    }

    .ui-corner-top {
        @include border-radius-top($borderRadius);
    }

    .ui-corner-bottom {
        @include border-radius-bottom($borderRadius);
    }

    .ui-corner-left {
        @include border-radius-left($borderRadius);
    }

    .ui-corner-right {
        @include border-radius-right($borderRadius);
    }

    a {
        color: $linkColor;
        text-decoration: none;

        &:hover {
            color: $linkHoverColor;
        }

        &:active {
            color: $linkActiveColor;
        }
    }

    .ui-helper-reset {
        line-height: normal;
    }

    .ui-state-disabled, .ui-widget:disabled {
        @include opacity($disabledOpacity);
    }

    .pi {
        font-size: $primeIconFontSize;
    }

    ::-webkit-input-placeholder {color:$inputPlaceholderTextColor}
    :-moz-placeholder           {color:$inputPlaceholderTextColor}
    ::-moz-placeholder          {color:$inputPlaceholderTextColor}
    :-ms-input-placeholder      {color:$inputPlaceholderTextColor}
}
