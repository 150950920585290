.ui-autocomplete {
    width: 100% !important;

    &.ui-autocomplete-multiple{
        .ui-autocomplete-multiple-container {

            .ui-autocomplete-input-token {
                width: 100%;
                border: none;

                input {
                    width: 100%;
                    border: none;
                }
            }

            .ui-autocomplete-token{
                margin: .125em;  // $chipsItemMargin
            }
        }
    }
}
