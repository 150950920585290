.ui-menu {
    .ui-menuitem-link {
        .ui-menuitem-text,
        .ui-menuitem-icon {
            color: $textColor;
        }

        &.ui-state-active{
            .ui-menuitem-text{
                color: $menuitemTextColor;
            }
        }

        &:hover{
            background-color: $primaryDarkColor;

            .ui-menuitem-text{
                color: $menuitemTextColor;
            }
        }
    }
}
