.ui-slidemenu {
    padding: $menuPadding;
    background-color: $menuBgColor;
    border: $menuBorder;

    .ui-menuitem-link {
        @include menuitem-link();
    }

    .ui-submenu-list {
        padding: $submenuPadding;
        background-color: $menuBgColor;
        border: 0 none;
    }

    .ui-menuitem {
        margin: $menuitemMargin;

        .ui-menuitem-link {
            .ui-submenu-icon {
                right: 0.429em;
            }
        }

        &.ui-menuitem-active {
            > .ui-menuitem-link {
                background-color: $menuitemActiveBgColor;

                .ui-menuitem-text {
                    color: $menuitemActiveTextColor;
                }

                .ui-menuitem-icon, .ui-submenu-icon {
                    color: $menuitemActiveIconColor;
                }
            }
        }
    }

    .ui-slidemenu-backward {
        margin: $submenuHeaderMargin;
        padding: $panelHeaderPadding;
        color: $panelHeaderTextColor;
        background-color: $panelHeaderBgColor;
        font-weight: $panelHeaderFontWeight;
        border: 0 none;

        &:hover {
            background-color: $panelHeaderHoverBgColor;
            color: $panelHeaderTextColor;
        }
    }

    .ui-menu-separator {
        border: $menuBorder;
        border-width: 1px 0 0 0;
    }
}
