.ui-panel {
    padding-bottom: 1em;

    .ui-panel-titlebar {
        align-items: center;

        .ui-panel-titlebar-icon {
            &:hover {
                color: $linkHoverColor;
            }
        }
    }

    .ui-panel-content {
        padding: 0.5em 0.75em; // $panelContentPadding
    }
}
