@import 'mixins';
@import 'fonts';
@import 'common';
@import 'components/utilities';
@import 'extensions';

body {
    //Input
    @import 'components/input/autocomplete';
    @import 'components/input/calendar';
    @import 'components/input/checkbox';
    @import 'components/input/chips';
    @import 'components/input/dropdown';
    @import 'components/input/editor';
    @import 'components/input/inputgroup';
    @import 'components/input/inputswitch';
    @import 'components/input/inputtext';
    @import 'components/input/listbox';
    @import 'components/input/multiselect';
    @import 'components/input/password';
    @import 'components/input/radiobutton';
    @import 'components/input/rating';
    @import 'components/input/selectbutton';
    @import 'components/input/slider';
    @import 'components/input/spinner';
    @import 'components/input/togglebutton';

    //Button
    @import 'components/button/button';
    @import 'components/button/splitbutton';

    //Data
    @import 'components/data/carousel';
    @import 'components/data/dataview';
    @import 'components/data/fullcalendar';
    @import 'components/data/orderlist';
    @import 'components/data/organizationchart';
    @import 'components/data/paginator';
    @import 'components/data/picklist';
    @import 'components/data/table';
    @import 'components/data/tree';
    @import 'components/data/treetable';
    @import 'components/data/virtualscroller';

    //Panel
    @import 'components/panel/accordion';
    @import 'components/panel/card';
    @import 'components/panel/fieldset';
    @import 'components/panel/panel';
    @import 'components/panel/tabview';
    @import 'components/panel/toolbar';

    //Overlay
    @import 'components/overlay/dialog';
    @import 'components/overlay/lightbox';
    @import 'components/overlay/overlaypanel';
    @import 'components/overlay/sidebar';
    @import 'components/overlay/tooltip';

    //File
    @import 'components/file/fileupload';

    //Menu
    @import 'components/menu/breadcrumb';
    @import 'components/menu/contextmenu';
    @import 'components/menu/megamenu';
    @import 'components/menu/menu';
    @import 'components/menu/menubar';
    @import 'components/menu/panelmenu';
    @import 'components/menu/slidemenu';
    @import 'components/menu/steps';
    @import 'components/menu/tabmenu';
    @import 'components/menu/tieredmenu';

    //Charts

    //Messages
    @import 'components/messages/message';
    @import 'components/messages/messages';
    @import 'components/messages/toast';

    //MultiMedia
    @import 'components/multimedia/galleria';

    //Misc
    @import 'components/misc/inplace';
    @import 'components/misc/progressbar';
    @import 'components/misc/terminal';

	//Forms
	@import 'components/forms';
}


