h1, .h1, h2, .h2 {
    margin-top: 10px;
    margin-bottom: 10px;
}

.input-invalid-chassi {
    border: 1px solid #ec2626;
}

.input-valid-chassi {
    border: 1px solid #0c8e1c;
}

body .ui-datepicker .ui-datepicker-header .ui-datepicker-title select {
    margin-top: -0.35em;
    margin-bottom: 0;
    transition: color 0.2s;
    width: 100px !important;
}