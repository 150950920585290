.ui-chips {
    > ul.ui-inputtext {
        padding: $inputPadding * 0.5 $inputPadding;
        display: inline-block;

        &:not(.ui-state-disabled):hover {
            border-color: $inputHoverBorderColor;
        }

        &:not(.ui-state-disabled).ui-state-focus {
            @include focused-input();
        }

        .ui-chips-input-token {
            padding: $inputPadding * 0.5 0;

            input {
                font-family: $fontFamily;
                font-size: $fontSize;
                padding: 0;
                margin: 0;
                color: $textColor;

                &:hover {
                    border: 0 none;
                }

                &:focus {
                    border: 0 none;
                }
            }
        }

        .ui-chips-token {
            font-size: $fontSize;
            padding: $inputPadding * 0.5 $inputPadding;
            margin: $chipsItemMargin;
            background: $highlightBgColor;
            color: $highlightColorText;
        }
    }
}

p-chips.ng-dirty.ng-invalid > .ui-inputtext {
    border: $inputErrorBorder;
}
