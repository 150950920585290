.ui-virtualscroller {
    .ui-virtualscroller-header {
        background-color: $panelHeaderBgColor;
        color: $panelHeaderTextColor;
        border: $panelHeaderBorder;
        padding: $panelHeaderPadding;
        font-weight: $panelHeaderFontWeight;
        border-bottom: 0 none;
    }

    .ui-virtualscroller-content {
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        .ui-virtualscroller-list {
            li {
                border-bottom: $panelHeaderBorder;
            }
        }
    }

    .ui-virtualscroller-footer {
        background-color: $panelFooterBgColor;
        color: $panelFooterTextColor;
        border: $panelFooterBorder;
        padding: $panelFooterPadding;
        border-top: 0 none;
    }
}
