.ui-dialog {
    .ui-dialog-titlebar {
        border: none;
    }

    .ui-dialog-footer {
        border: none;
    }

    &.ui-confirmdialog {
        width: 35em;
            .ui-dialog-content {
                border: none;
                display: flex;
            }
    }

}

