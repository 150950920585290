.ui-progressbar {
    border: $progressBarBorder;
    height: $progressBarHeight;
    background-color: $progressBarBgColor;

    .ui-progressbar-value {
        border: 0 none;
        margin: 0;
        background: $progressBarValueBgColor;
    }

    .ui-progressbar-label {
        margin-top: 0;
        color: $textColor;
        line-height: $progressBarHeight;
    }
}
