.ui-toast {
    .ui-toast-close-icon {
        text-decoration: none;
    }

    @media screen and (min-width: $breakpoint-xs) {
        width: 25em;

        .ui-toast-message {
            font-size: 20px;
        }
    }
}
