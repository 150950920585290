.ui-sidebar{
    padding: 0;

    // side bar close icon color
    .ui-sidebar-close{
        color: $primaryDarkerColor;
        font-size: 1.7em;
        padding: .125em .25em;
    }
}
