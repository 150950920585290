.ui-tree {
    border: $panelContentBorder;
    background-color: $panelContentBgColor;
    color: $panelContentTextColor;
    padding: $panelContentPadding;

    .ui-tree-container {
        padding: 0.286em;
        margin: 0;

        .ui-treenode {
            padding: $treeNodePadding;

            .ui-treenode-content {
                padding: 0;
                border: 1px solid transparent;
                @include transition($inputListItemTransition);
                @include border-radius($borderRadius);

                .ui-tree-toggler {
                    vertical-align: middle;
                    display: inline-block;
                    float: none;
                    margin: 0 $treeNodeContentSpacing 0 0;
                    color: $dataIconColor;
                }

                .ui-treenode-icon {
                    vertical-align: middle;
                    display: inline-block;
                    margin: 0 $treeNodeContentSpacing 0 0;
                    color: $dataIconColor;
                }

                .ui-treenode-label {
                    margin: 0;
                    vertical-align: middle;
                    display: inline-block;
                    padding: $treeNodeLabelPadding;

                    &.ui-state-highlight {
                        background-color: $highlightBgColor;
                        color: $highlightColorText;
                    }
                }

                .ui-chkbox {
                    margin: 0 $inlineSpacing 0 0;

                    .ui-chkbox-icon {
                        margin: 1px 0 0 0;
                    }
                }

                &.ui-treenode-selectable {
                    .ui-treenode-label:not(.ui-state-highlight):hover {
                        background-color: $inputListItemHoverBgColor;
                        color: $inputListItemHoverTextColor;
                    }
                }

                > span {
                    line-height: inherit;
                }

                &.ui-treenode-dragover {
                    background-color: $inputListItemHoverBgColor;
                    color: $inputListItemHoverTextColor;
                }

                &:focus {
                    @include focused();
                }
            }
        }

        .ui-treenode-droppoint {
            &.ui-treenode-droppoint-active {
                background-color: $highlightBgColor;
            }
        }
    }

    &.ui-tree-horizontal {
        padding-left: 0;
        padding-right: 0;

        .ui-treenode {

            .ui-treenode-content {
                border: $panelContentBorder;
                background-color: $panelContentBgColor;
                color: $panelContentTextColor;
                padding: $panelContentPadding;
                @include transition($inputListItemTransition);

                &.ui-state-highlight {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;
                }

                .ui-chkbox {
                    .ui-icon {
                        color: $primaryColor;
                    }
                }

                .ui-treenode-label:not(.ui-state-highlight):hover {
                    background-color: inherit;
                    color: inherit;
                }

                &.ui-treenode-selectable:not(.ui-state-highlight):hover {
                    background-color: $inputListItemHoverBgColor;
                    color: $inputListItemHoverTextColor;
                }

                &:focus {
                    @include focused();
                }
            }
        }
    }

    .ui-tree-filter-container {
        .ui-tree-filter {
            width: 100%;
            padding-right: 2em;
        }

        .ui-tree-filter-icon {
            top: 50%;
            left: auto;
            margin-top: -.5em;
            right: .8em;
            color: $inputListHeaderSearchIconColor;
        }
    }
}
