.ui-megamenu {
    padding: $menuPadding;
    background-color: $menuBgColor;
    border: $menuBorder;

    .ui-menuitem-link {
        @include menuitem-link();
    }

    .ui-megamenu-submenu-header {
        margin: $submenuHeaderMargin;
        padding: $panelHeaderPadding;
        color: $panelHeaderTextColor;
        background-color: $panelHeaderBgColor;
        font-weight: $panelHeaderFontWeight;
        border: 0 none;
    }

    .ui-megamenu-panel {
        padding: $submenuPadding;
        background-color: $menuBgColor;
        border: $overlayMenuBorder;
        @include shadow($overlayMenuShadow);
    }

    .ui-menuitem {
        margin: $menuitemMargin;

        .ui-menuitem-link {
            .ui-submenu-icon {
                right: 0.429em;
            }
        }

        &.ui-menuitem-active {
            > .ui-menuitem-link {
                background-color: $menuitemActiveBgColor;

                .ui-menuitem-text {
                    color: $menuitemActiveTextColor;
                }

                .ui-menuitem-icon, .ui-submenu-icon {
                    color: $menuitemActiveIconColor;
                }
            }
        }
    }

    &.ui-megamenu-vertical {
        .ui-megamenu-root-list {
            > .ui-menuitem {
                > .ui-menuitem-link {
                    > .ui-submenu-icon {
                        right: 0.429em;
                    }
                }
            }
        }
    }
}
