.ui-dataview {
    .ui-dataview-header {
        background-color: $panelHeaderBgColor;
        color: $panelHeaderTextColor;
        border: $panelHeaderBorder;
        padding: $panelHeaderPadding;
        font-weight: $panelHeaderFontWeight;
        border-bottom: 0 none;
    }

    .ui-dataview-content {
        padding: $panelContentPadding;
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;
    }

    .ui-dataview-footer {
        background-color: $panelFooterBgColor;
        color: $panelFooterTextColor;
        border: $panelFooterBorder;
        padding: $panelFooterPadding;
        border-top: 0 none;
    }

    .ui-dataview-layout-options {
        .ui-button {
            @include transition($buttonTransition);

            &:focus {
                outline: $buttonFocusOutline;
                outline-offset: $buttonFocusOutlineOffset;
                @include shadow($buttonFocusShadow);
                z-index: 1;
            }
        }
    }
}
