.ui-toolbar {
    background-color: $panelHeaderBgColor;
    border: $panelHeaderBorder;
    padding: $panelHeaderPadding;

    button {
        vertical-align: middle;
    }

    .ui-toolbar-separator {
        vertical-align: middle;
        color: $panelHeaderIconColor;
        margin: 0 $inlineSpacing;
    }
}
