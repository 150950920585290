// ******************************************************
// ****** START custom variables to support Ford   ******
// ****** Antenna Fonts and Ford Design System     ******
// ****** Colors                                   ******
// ******************************************************

// override PrimeNG theme font family
$fontFamilyDefault: Ford Antenna Light, "Trebuchet MS", Arial, Helvetica, sans-serif;
$fontFamily: $fontFamilyDefault;
$fontFamilyFordAntenna: Ford Antenna, Arial, Helvetica, sans-serif;
$fontFamilyFordAntennaLight: Ford Antenna Light, Arial, Helvetica, sans-serif;
$fontFamilyFordAntennaCond: Ford Antenna, Arial, Helvetica, sans-serif;

//Font paths with obfuscated names from WebType

// note: due to scss import syntax, this directory does not match the filesystem location of the fonts. instead,
// it needs to be relative to the app's scss entry point - by default, this is assets/blue-oval-theme/theme.scss
$FontDirectory: "../webcore/fonts/";

$FordAntennaGUID: $FontDirectory + "Ford-Antenna-Regular/a3a5e8f2-b656-4837-8a16-f448f0edd2b8";
$FordAntennaBoldGUID: $FontDirectory + "Ford-Antenna-Bold/1f6002e2-42c0-4332-8629-1dad7eb882ca";
$FordAntennaRegularItalicGUID: $FontDirectory + "Ford-Antenna-Regular-Italic/d3619808-0ee3-4f20-84c5-1cca83dfec44";
$FordAntennaBoldItalicGUID: $FontDirectory + "Ford-Antenna-Bold-Italic/fb224575-dc8a-414f-8f22-034559e3bdba";

$FordAntennaMediumGUID: $FontDirectory + "Ford-Antenna-Medium/2b3c6cec-b81d-4903-89a0-aa111b549094";

$FordAntennaLightGUID: $FontDirectory + "Ford-Antenna-Light/11f33cf4-3e86-4d2b-b2df-9e2ae91ba4b5";
$FordAntennaLightItalicGUID: $FontDirectory + "Ford-Antenna-Light-Italic/2f230fc8-026f-4a1a-82f5-2f116e1e5853";

$FordAntennaCondensedGUID: $FontDirectory + "Ford-Antenna-Cond-Regular/328c7edc-c164-4098-aa60-405620c7d1dd";
$FordAntennaCondensedBoldGUID: $FontDirectory + "Ford-Antenna-Cond-Bold/3528ac26-02ec-4e01-a488-8831ab9bd963";
$FordAntennaCondensedRegularItalicGUID: $FontDirectory + "Ford-Antenna-Cond-Regular-Italic/6d453efb-6fc1-43ae-b9f7-704e5d3b4c09";
$FordAntennaCondensedBoldItalicGUID: $FontDirectory + "Ford-Antenna-Cond-Bold-Italic/84eb87b4-c7d9-4258-8efd-58c9ad5689d9";

$FordAntennaCondensedMediumGUID: $FontDirectory + "Ford-Antenna-Cond-Medium/cd001810-535d-4e5a-b707-6799a4953db8";
$FordAntennaCondensedMediumItalicGUID: $FontDirectory + "Ford-Antenna-Cond-Medium-Italic/768635c0-3484-4e43-b5bb-1fab81563224";

$WOFF2Postfix: "-5.woff2";
$WOFFPostfix: "-3.woff";
$TTFPostfix: "-1.ttf";


// FDS prefix relates to Ford Design System determined values
// FDS Link: https://azureford.sharepoint.com/sites/DigitalDesignSystem/
// WC prefix related to Web Enablement WebCore determined values

// Color Palette
//--------------------------------------------------
//ui component Colors coming from the WCAG 2.0 AA Accessibility Compliance guide from GTB


// Backgrounds
// Blue
$FDS-ford-blue: #102B4E;
$FDS-uniting-blue: #2861A4;

// Gray
$FDS-light-gray: #F2F2F2;
$FDS-disabled-light_dark-background: #8C8C8C;
$FDS-disabled-dark_light-background: #6E6E6E;
$FDS-dark-gray: #4D4D4D;
$FDS-surface: #F4F4F4;

// table even row color - NOTE - This deviates from the style guide, we needed a darker color for
//	the sake of visibility and differentiation between even and odd rows of a table.
$even-row-color: #F1F1F1;

// Text and Icons
// CTA Colors
$CTA-dark-cyan: #0276B3;
$CTA-light-cyan: #37A9E8;
$CTA-icon-cyan: #2096CD;

// Text Dark
$charcoal: #333333;

// Text Light
$white: #FFFFFF;

// Alerts
$FDS-error: #D62D0A;
$light-alert-red: lighten($FDS-error, 20%);

$FDS-caution_graphical-objects: #DA7029;
$light-alert-orange: lighten($FDS-caution_graphical-objects, 20%);

$FDS-success: #008200;
$light-alert-green: lighten($FDS-success, 20%);

// Required Field star color
$red-star: $FDS-error;

// Responsive
//--------------------------------------------------
// Breakpoints
$breakpoint-xs: 480px !default;
$breakpoint-sm: 576px !default;
$breakpoint-md: 768px !default;
$breakpoint-lg: 992px !default;
$breakpoint-xl: 1200px !default;
$breakpoint-gutter: .5em !default;

// Transitions
$app-header-font-transition: font-size .25s ease-in;
$app-header-width-transition: max-width .25s ease-in;
$app-header-margin-transition: margin-top .25s ease-in;

// ******************************************************
// ****** END custom variables to support Ford     ******
// ****** Antenna Fonts and Ford Design System     ******
// ****** Colors                                   ******
// ******************************************************
