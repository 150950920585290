p-dropdown {
    &.inline-dropdown-pad {
        padding-left: 0.5em;
    }
}

.ui-dropdown {
    &.form-dropdown {
        width: 100% !important;
    }
}

