.ui-menubar{
    font-family: $fontFamilyFordAntennaLight;

    a {
        &.user-info {
            color: $primaryTextColor;
        }
    }

    .ui-menubar-custom {
        .user-info {
            float: right;
            margin-right: 1em;
            margin-top: 0.8em;
            color: $primaryTextColor;
        }
    }

    .ui-submenu-list{
        .ui-menuitem-link {
            .ui-menuitem-text,
            .ui-menuitem-icon {
                color: $charcoal;
            }
        }

        .ui-menuitem.ui-menuitem-active > .ui-menuitem-link .ui-menuitem-text,
        .ui-menuitem-link:hover .ui-menuitem-text{
            color: $primaryTextColor;
        }
    }
}
